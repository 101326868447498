<template>
  <div class="contain" :style="{ backgroundImage: `url(${bgImg})` }">
    <h2>进货商城</h2>
    <van-form @submit="onSubmit">
      <van-field
        v-model="username"
        name="account"
        label="用户名"
        placeholder="用户名"
        :rules="[{ required: true, message: '请填写用户名' }]"
      />
      <van-field
        v-model="password"
        type="password"
        name="password"
        label="密码"
        placeholder="密码"
        :rules="[{ required: true, message: '请填写密码' }]"
      />
      <div style="margin: 16px">
        <van-button round block type="info" native-type="submit"
          >提交</van-button
        >
      </div>
    </van-form>
    <a href="https://beian.miit.gov.cn" class="icp">粤ICP备2021056371号</a>
  </div>
</template>

<script>
import bgImg from '../assets/img/webp.webp'
export default {
  data () {
    return {
      bgImg: bgImg,
      username: '',
      password: '',
    }
  },
  methods: {
    async onSubmit (values) {
      let value = this.$qs.stringify({
        account: values.account,
        password: values.password
      })

      let res = await this.$http.post('login/login', value);
      if (res) {

        this.$store.dispatch('loginAsync', res.data.data);
        this.$router.replace('/home')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.icp {
  width: 100%;
  position: fixed;
  bottom: 5%;
  left: 0;
  color: white;
  text-align: center;
}
.contain {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  padding-top: 20%;
  align-items: center;

  background-size: cover;
  h2 {
    text-align: center;
    font-size: 35px;
  }
}
.contain::before {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  display: block;
  content: "";
}
.van-cell__title {
  color: #fff;
}
.van-cell {
  background: none;
}
input {
  background: none;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
</style>

